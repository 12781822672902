@import '~bootstrap/scss/bootstrap';



.App-header{
  p{
    color: red;
  }
  .App-logo{
    width: 100px;
  }
}