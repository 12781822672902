//theme
@import "primereact/resources/themes/lara-light-indigo/theme.css"; 
@import 'primeicons/primeicons.css';
        

// primereact/resources/themes/bootstrap4-light-blue/theme.css
// primereact/resources/themes/bootstrap4-light-purple/theme.css
// primereact/resources/themes/bootstrap4-dark-blue/theme.css
// primereact/resources/themes/bootstrap4-dark-purple/theme.css
// primereact/resources/themes/md-light-indigo/theme.css
// primereact/resources/themes/md-light-deeppurple/theme.css
// primereact/resources/themes/md-dark-indigo/theme.css
// primereact/resources/themes/md-dark-deeppurple/theme.css
// primereact/resources/themes/mdc-light-indigo/theme.css
// primereact/resources/themes/mdc-light-deeppurple/theme.css
// primereact/resources/themes/mdc-dark-indigo/theme.css
// primereact/resources/themes/mdc-dark-deeppurple/theme.css
// primereact/resources/themes/tailwind-light/theme.css
// primereact/resources/themes/fluent-light/theme.css
// primereact/resources/themes/lara-light-blue/theme.css
// primereact/resources/themes/lara-light-indigo/theme.css
// primereact/resources/themes/lara-light-purple/theme.css
// primereact/resources/themes/lara-light-teal/theme.css
// primereact/resources/themes/lara-dark-blue/theme.css
// primereact/resources/themes/lara-dark-indigo/theme.css
// primereact/resources/themes/lara-dark-purple/theme.css
// primereact/resources/themes/lara-dark-teal/theme.css
// primereact/resources/themes/soho-light/theme.css
// primereact/resources/themes/soho-dark/theme.css
// primereact/resources/themes/viva-light/theme.css
// primereact/resources/themes/viva-dark/theme.css
// primereact/resources/themes/mira/theme.css
// primereact/resources/themes/nano/theme.css
// primereact/resources/themes/saga-blue/theme.css
// primereact/resources/themes/saga-green/theme.css
// primereact/resources/themes/saga-orange/theme.css
// primereact/resources/themes/saga-purple/theme.css
// primereact/resources/themes/vela-blue/theme.css
// primereact/resources/themes/vela-green/theme.css
// primereact/resources/themes/vela-orange/theme.css
// primereact/resources/themes/vela-purple/theme.css
// primereact/resources/themes/arya-blue/theme.css
// primereact/resources/themes/arya-green/theme.css
// primereact/resources/themes/arya-orange/theme.css
// primereact/resources/themes/arya-purple/theme.css
//core
@import "primereact/resources/primereact.min.css";    
@import "./Assets/Styles/Index.scss";                                  
        
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// Pseudo::scrollbar-thumb element
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #4338CA;
  box-shadow: inset 0 0 3px #4338CA;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0,0,0,.3);
  border-radius: 6px;
}